* {
    /*border: green 10px solid !important;*/
    //background-color: red;
}

html[data-theme="dark"]  {
    html, body  {
        --headings: #74fcf1;
        --links: #4cb400;
        --highlight: #41c772;
        --bg: #181818;
        --bg-secondary: #2a2a2a;
        --text: #adb5bd;
        --code-text: #c8ff00;
        --share-text: #C4C4C4;
    };
}

pre, code {
    font-family: "Fira Mono", monospace;
}

.page__content {
    text-align: justify;
}
