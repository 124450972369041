/* fira-mono-regular - latin */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-mono-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/fira-mono-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/fira-mono-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/fira-mono-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/fira-mono-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/fira-mono-v14-latin-regular.svg#FiraMono') format('svg'); /* Legacy iOS */
}

/* fira-code-regular - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-code-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/fira-code-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/fira-code-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/fira-code-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/fira-code-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/fira-code-v21-latin-regular.svg#FiraCode') format('svg'); /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v11-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v11-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v11-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}

/* anonymous-pro-regular - latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/anonymous-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/anonymous-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/anonymous-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/anonymous-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/anonymous-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/anonymous-pro-v21-latin-regular.svg#AnonymousPro') format('svg'); /* Legacy iOS */
}
